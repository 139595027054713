module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"www.schueleraustausch.info","short_name":"www.schueleraustausch.info","start_url":"/","background_color":"#ffffff","theme_color":"#5C3993","display":"standalone","icon":"src/images/emblem.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ef58674982db2a66af72fc8420621db"},
    },{
      plugin: require('../node_modules/gatsby-source-akcms-v2/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"f63b87d891a819423a9db0e31051c32ee07110e727f5b733","apiBaseUrl":"https://api.cms.austauschkompass.de/api/v1"},
    },{
      plugin: require('../node_modules/gatsby-source-akratings-high-school/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://www.schueleraustausch.de/api/v2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"15","matomoUrl":"https://stats.schueleraustausch.net","siteUrl":"https://www.schueleraustausch.info"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
